import React, { useEffect, useRef, useState } from 'react'
import { useQueryClient } from 'react-query'
// @material-ui/core components
import { CircularProgress } from '@material-ui/core'
import Avatar from '@material-ui/core/Avatar'
import Fade from '@material-ui/core/Fade'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ListItemText from '@material-ui/core/ListItemText'
import { makeStyles } from '@material-ui/core/styles'
import Switch from '@material-ui/core/Switch'
import Tooltip from '@material-ui/core/Tooltip'
// @material-ui/icons
import AddAlert from '@material-ui/icons/AddAlert'
import AssessmentIcon from '@material-ui/icons/Assessment'
import Add from '@material-ui/icons/PlaylistAdd'
import TouchApp from '@material-ui/icons/TouchApp'

// components
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs.js'
import Card from 'components/Card/Card.js'
import CardBody from 'components/Card/CardBody.js'
import CardHeader from 'components/Card/CardHeader.js'
import Button from 'components/CustomButtons/Button.js'
import ExpiredDiv from 'components/ExpiredDiv/ExpiredDiv'
import NotificationAlert from 'components/NotificationAlert/NotificationAlert'
import Snackbar from 'components/Snackbar/Snackbar.js'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'

// style
import styles from '../../assets/jss/material-dashboard-pro-react/views/Apps/nitorConnectStyle.js'

// Views
import {
  configTenantKey,
  useGetTenantConfig,
  useUpdateTenantConfigDefaultApp
} from 'hooks/useTenantConfig.js'
import { cloneObject, getUserTenant, hideLoading, showLoading } from 'utils/functions.js'
import Wizard from '../../components/Wizard/Wizard.js'
import { saveInvoiceConfiguration } from '../../services/apiApps.js'
import AppMainMenu from '../CustomGenericViews/AppMainMenu.js'
import ParametersAccordion from '../CustomGenericViews/ParametersAccordion.js'
import { AlertDialogDefaultApp } from './DialogDefaultApp.js'
import { getAppDetails } from './InvoiceContainer.js'

const useStyles = makeStyles(styles)

export default function DataAssure(props) {
  const [configurationDetails, setConfigurationDetails] = useState('main')
  const queryClient = useQueryClient()
  const { data: configTenant, isLoading, isError } = useGetTenantConfig()
  const updatedTenantConfig = useUpdateTenantConfigDefaultApp()
  const { defaultApp } = configTenant || {}
  const [isErrorDefaultApp, setIsErrorDefaultApp] = useState(false)
  const [service, setService] = useState('')
  const [isOpenDefaultApp, setIsOpenDefaultApp] = useState({
    isOpen: false,
    eventSwitch: null
  })
  const refreshTableFunction = useRef(() => {})
  const wizardRef = useRef(null)

  const [defaultAppUI, setDefaultAppUI] = useState({})

  let breadcrumbViews = [
    {
      name: 'Invoice AI',
      url: '/admin/invoiceAi/configurations',
      icon: AssessmentIcon
    }
  ]

  useEffect(() => {
    if (updatedTenantConfig.isError) {
      setIsErrorDefaultApp(true)
    }
  }, [updatedTenantConfig.isError])

  useEffect(() => {
    if (isLoading || isError || !defaultApp || defaultApp === '') {
      return
    }

    setDefaultAppUI({ [defaultApp]: true })
  }, [isLoading, isError, defaultApp])

  function handleChangeSwitch(event) {
    if (!event.target.checked) {
      return
    }
    setIsOpenDefaultApp({
      isOpen: true,
      eventSwitch: { name: event.target.name, checked: event.target.checked }
    })
  }
  function handleClose() {
    setIsOpenDefaultApp({ ...isOpenDefaultApp, isOpen: false })
  }

  function handleAcceptModal(eventDefaultApp) {
    const { eventSwitch } = eventDefaultApp
    setIsOpenDefaultApp({ ...eventDefaultApp, isOpen: false })
    updatedTenantConfig.mutate(eventSwitch.name, {
      onSettled: () => {
        queryClient.invalidateQueries([configTenantKey, getUserTenant()])
        queryClient.invalidateQueries('invoices')
      }
    })
  }

  function executeScroll() {
    if (wizardRef.current) {
      wizardRef.current.scrollIntoView()
    }
  }

  async function saveToDataBase(
    configurationProps,
    rollbackFunction,
    successMessage,
    refreshPage = false,
    changeConfigured = true,
    errorMessage = 'Something went wrong, please try again later'
  ) {
    let newConfigurationProp = cloneObject(configurationProps)

    delete newConfigurationProp.displayMode
    delete newConfigurationProp.mapping
    let keys = Object.keys(newConfigurationProp)
    keys.forEach(key => {
      if (typeof newConfigurationProp[key] === 'object') {
        if (newConfigurationProp[key].configurationOnClick)
          delete newConfigurationProp[key].configurationOnClick
        if (newConfigurationProp[key].parameters) delete newConfigurationProp[key].parameters
        if (newConfigurationProp[key].mappings) delete newConfigurationProp[key].mappings
        if (key === 'parameters' && newConfigurationProp[key].rfc) {
          let item
          for (item of newConfigurationProp[key].rfc.value) {
            delete item.isArrayChild
            delete item.label
          }
        }
      }
    })
    showLoading()
    executeScroll()

    let response = await saveInvoiceConfiguration(
      'invoiceAI',
      props.validationsForm.env,
      newConfigurationProp,
      props.validationsForm.service,
      configurationDetails
    )
    if (response && response.success) {
      props.setValidationMessage(successMessage)
      props.setSubmitSuccess(true)
      setTimeout(() => {
        props.setSubmitSuccess(false)
      }, 5000)
      await getAppDetails(props, props.validationsForm.env)
    } else {
      props.setValidationMessage(errorMessage)
      props.setCreateError(true)
      setTimeout(() => {
        props.setCreateError(false)
      }, 5000)
      if (rollbackFunction) {
        rollbackFunction(() => {
          props.setRefresh(!props.refresh)
          if (refreshTableFunction.current) refreshTableFunction.current()
        })
      }
    }
    hideLoading()
  }

  const classes = useStyles()

  async function onSaveApp(params) {
    const isSucess = await props.onAppSaved(params)

    if (isSucess) {
      queryClient.invalidateQueries([configTenantKey, getUserTenant()])
    }
  }

  function getView() {
    if (props.pageIsLoading) {
      return <div></div>
    }

    function scrolltoTop() {
      let element = document.getElementById('MainGrid')
      if (element) element.scrollTo(0, 0)
    }

    function getAdminSteps() {
      let switchChildren = {}
      let additionalConfigurationsChildren = {}
      Object.keys(props.validationsForm).forEach(key => {
        if (props.validationsForm[key] && props.validationsForm[key].configurable) {
          if (props.validationsForm[key].section === 'integration') {
            switchChildren[key] = props.validationsForm[key]
            switchChildren[key].id = key
            switchChildren[key].configurationOnClick = (id, state) => {
              props.history.push(
                `/admin/invoiceAI/${switchChildren[key].api}/${props.validationsForm.appId}`
              )
            }
          } else if (props.validationsForm[key].section === 'additionalConfigurations') {
            additionalConfigurationsChildren[key] = props.validationsForm[key]
            additionalConfigurationsChildren[key].id = key
            additionalConfigurationsChildren[key].configurationOnClick = (id, state) => {
              props.history.push(
                `/admin/invoiceAI/additionalConfigurations/${additionalConfigurationsChildren[key].api}/${props.validationsForm.appId}`
              )
            }
          }
        }
      })
      let validationInitialState = props.validationsForm
      validationInitialState.env = validationInitialState.appId

      let allGeneralParameters =
        Object.keys(props.validationsForm.parameters).length > 0
          ? props.validationsForm.parameters
          : {}

      let allApiForm =
        Object.keys(props.validationsForm.parameters).length > 0
          ? props.validationsForm.parameters.apiForm
            ? props.validationsForm.parameters.apiForm
            : {}
          : {}
      let allApiProcurementForm =
        Object.keys(props.validationsForm.parameters).length > 0
          ? props.validationsForm.parameters.apiProcurementForm
            ? props.validationsForm.parameters.apiProcurementForm
            : {}
          : {}
      let customization = {}
      let generalParameters = {}
      let emailIntegration = {}
      let destinations = {}
      let documentApprovalApi = {}
      let flowExtensionApi = {}
      let masterDataRetrievalProcurementApi = {}
      let coreApi = {}
      let exportConfiguration = {}
      let aiIntelligence = {}
      // let decisionsEngineConnection = {}
      let parameterKeys = Object.keys(allGeneralParameters)
      let parameterKeysQ = Object.keys(allApiForm)
      let parameterKeysS = Object.keys(allApiProcurementForm)
      parameterKeys.forEach(key => {
        if (allGeneralParameters[key].includeIn === 'documentApprovalApi') {
          documentApprovalApi[key] = allGeneralParameters[key]
          // } else if (
          //   allGeneralParameters[key].includeIn === 'decisionsEngineConnection'
          // ) {
          //   decisionsEngineConnection[key] = allGeneralParameters[key]
          //   if (key === 'linkConnections') {
          //     decisionsEngineConnection[
          //       key
          //     ].route = `/admin/invoiceAI/connections/${props.selectedAppId}`
          //   }
        } else if (allGeneralParameters[key].includeIn === 'emailIntegration') {
          emailIntegration[key] = allGeneralParameters[key]
        } else if (allGeneralParameters[key].includeIn === 'destinations') {
          destinations[key] = allGeneralParameters[key]
        } else if (allGeneralParameters[key].includeIn === 'customization') {
          customization[key] = allGeneralParameters[key]
        } else if (allGeneralParameters[key].includeIn === 'coreApi') {
          coreApi[key] = allGeneralParameters[key]
        } else if (allGeneralParameters[key].includeIn === 'exportConfiguration') {
          exportConfiguration[key] = allGeneralParameters[key]
        } else if (allGeneralParameters[key].includeIn === 'documentIntelligence') {
          aiIntelligence[key] = allGeneralParameters[key]
        } else {
          generalParameters[key] = allGeneralParameters[key]
        }
      })

      parameterKeysQ.forEach(key => {
        if (allApiForm[key].includeIn === 'flowExtensionApi') {
          flowExtensionApi[key] = allApiForm[key]
        } else {
          generalParameters[key] = allApiForm[key]
        }
      })
      parameterKeysS.forEach(key => {
        if (allApiProcurementForm[key].includeIn === 'masterDataRetrievalProcurementApi') {
          masterDataRetrievalProcurementApi[key] = allApiProcurementForm[key]
        } else {
          generalParameters[key] = allApiProcurementForm[key]
        }
      })
      let mapping = {
        documentationPath: '/admin/invoiceAI/documentation',
        setService: setService,
        service: service,
        configureService: props.displayMode === 'create',
        configureRealm: false,
        enabled: props.validationsForm.enabled,
        mainSwitchText: 'Enable Invoice AI',
        mainSwitchOnChanged: (event, state) => {},
        title: 'Invoice AI Settings',
        topChildren: '',
        bottomChildren: <div></div>,
        applicationTitle: 'Application',
        switchChildrenTitle: Object.keys(switchChildren).length > 0 ? 'Integration' : '',
        switchChildren: switchChildren,
        additionalChildrenTitle:
          Object.keys(additionalConfigurationsChildren).length > 0
            ? 'Additional Configurations'
            : '',
        additionalConfigurationsChildren: additionalConfigurationsChildren
      }

      if (props.displayMode === 'create') {
        validationInitialState.env = ''
        mapping = {
          documentationPath: '/admin/invoiceAI/documentation',
          setService: setService,
          configureService: props.displayMode === 'create',
          configureRealm: false,
          enabled: props.validationsForm.enabled,
          mainSwitchText: 'Enable Invoice AI',
          mainSwitchOnChanged: (event, state) => {},
          title: 'Invoice AI Settings',
          topChildren: '',
          bottomChildren: <div></div>,
          applicationTitle: 'Application',
          switchChildrenTitle: '',
          switchChildren: {},
          additionalChildrenTitle: '',
          additionalConfigurationsChildren: {}
        }
      }

      validationInitialState.mapping = mapping

      let steps = [
        {
          stepName: 'Configuration',
          stepComponent: AppMainMenu,
          stepId: 'validations',
          initialState: validationInitialState
        }
      ]
      let connectivityParameters = []
      if (props.displayMode === 'edit') {
        steps.push({
          stepName: 'Connectivity',
          stepComponent: ParametersAccordion,
          stepId: 'connectivity',
          initialState: {
            parameters: connectivityParameters
          }
        })
        if (Object.keys(generalParameters).length > 0) {
          connectivityParameters.push({
            title: 'General Configurations',
            parentId: 'GeneralConfigurations',
            conectivityMapping: {
              env: props.validationsForm.appId,
              app: 'invoiceAI',
              ignoreIds: ['apiForm', 'apiProcurementForm'],
              service: props.validationsForm.service,
              setService: setService,
              hideServiceName: true,
              title: '',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              fieldsTitle: 'Integration',
              hideTestConnection: true,
              fields: generalParameters
            }
          })
        }
        if (Object.keys(emailIntegration).length > 0) {
          connectivityParameters.push({
            title: 'Email Integration',
            parentId: 'EmailIntegration',
            conectivityMapping: {
              env: props.validationsForm.appId,
              app: 'invoiceAI',
              service: props.validationsForm.service,
              setService: setService,
              hideServiceName: true,
              title: '',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              fieldsTitle: '',
              hideTestConnection: true,
              fields: emailIntegration
            }
          })
        }
        if (Object.keys(destinations).length > 0) {
          connectivityParameters.push({
            title: 'Invoice Destinations',
            parentId: 'InvoiceDestinations',
            conectivityMapping: {
              env: props.validationsForm.appId,
              app: 'invoiceAI',
              service: props.validationsForm.service,
              setService: setService,
              hideServiceName: true,
              title: '',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              fieldsTitle: '',
              hideTestConnection: true,
              fields: destinations
            }
          })
        }
        if (props.validationsForm.service === 'ariba' || props.validationsForm.service === 'ics') {
          connectivityParameters.push({
            title: 'Document Approval API',
            parentId: 'documentApprovalApi',
            conectivityMapping: {
              service: props.validationsForm.service,
              setService: setService,
              env: props.validationsForm.appId,
              app: 'invoiceAI',
              parametersPosition: '',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              hideServiceName: true,
              hideTestConnection: false,
              title: '',
              fieldsTitle: '',
              fields: documentApprovalApi,
              invoiceUrl: true
            }
          })
          connectivityParameters.push({
            title: 'Ariba Network Invoices',
            parentId: 'flowExtensionApi',
            conectivityMapping: {
              service: 'flowExtensionApi',
              setService: setService,
              env: props.validationsForm.appId,
              app: 'invoiceAI',
              parametersPosition: '',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              hideServiceName: true,
              hideTestConnection: false,
              title: '',
              fieldsTitle: '',
              fields: flowExtensionApi,
              invoiceUrl: true
            }
          })
          connectivityParameters.push({
            title: 'User Integration',
            parentId: 'masterDataRetrievalProcurementApi',
            conectivityMapping: {
              service: props.validationsForm.service,
              setService: setService,
              env: props.validationsForm.appId,
              app: 'invoiceAI',
              parametersPosition: '',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              hideServiceName: true,
              hideTestConnection: false,
              title: '',
              fieldsTitle: '',
              fields: masterDataRetrievalProcurementApi,
              invoiceUrl: true
            }
          })
          // connectivityParameters.push({
          //   title: 'Decisions Engine Connections',
          //   parentId: 'decisionsEngineConnection',
          //   conectivityMapping: {
          //     service: props.validationsForm.service,
          //     setService: setService,
          //     env: props.validationsForm.appId,
          //     app: 'invoiceAI',
          //     parametersPosition: '',
          //     region: props.validationsForm.parameters.region,
          //     siteUrl: props.validationsForm.parameters.siteUrl,
          //     hideServiceName: true,
          //     hideTestConnection: true,
          //     title: '',
          //     fieldsTitle: '',
          //     fields: decisionsEngineConnection,
          //     invoiceUrl: true
          //   }
          // })
        }
        if (props.validationsForm.service === 'coupa') {
          connectivityParameters.push({
            title: 'Core',
            parentId: 'coreApi',
            conectivityMapping: {
              service: props.validationsForm.service,
              setService: setService,
              env: props.validationsForm.appId,
              app: 'invoiceAI',
              parametersPosition: '',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              hideServiceName: true,
              hideTestConnection: false,
              title: '',
              fieldsTitle: '',
              fields: coreApi,
              customConnection: true,
              invoiceUrl: true
            }
          })
        }
        if (Object.keys(customization).length > 0) {
          connectivityParameters.push({
            title: 'Customization',
            parentId: 'customization',
            conectivityMapping: {
              env: props.validationsForm.appId,
              app: 'invoiceAI',
              service: props.validationsForm.service,
              setService: setService,
              hideServiceName: true,
              title: '',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              fieldsTitle: '',
              hideTestConnection: true,
              fields: customization
            }
          })
        }
        if (Object.keys(exportConfiguration).length > 0) {
          connectivityParameters.push({
            title: 'Export Configuration',
            parentId: 'exportConfiguration',
            conectivityMapping: {
              env: props.validationsForm.appId,
              app: 'invoiceAI',
              service: props.validationsForm.service,
              setService: setService,
              hideServiceName: true,
              title: '',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              fieldsTitle: '',
              hideTestConnection: true,
              fields: exportConfiguration
            }
          })
        }

        if (Object.keys(aiIntelligence).length > 0) {
          connectivityParameters.push({
            title: 'Document Intelligence',
            parentId: 'documentIntelligence',
            conectivityMapping: {
              env: props.validationsForm.appId,
              app: 'invoiceAI',
              service: props.validationsForm.service,
              setService: setService,
              hideServiceName: true,
              title: '',
              region: props.validationsForm.parameters.region,
              siteUrl: props.validationsForm.parameters.siteUrl,
              fieldsTitle: '',
              hideTestConnection: true,
              fields: aiIntelligence
            }
          })
        }
      }
      return steps
    }

    function returnWizardToDisplay() {
      return (
        <div ref={wizardRef}>
          <Wizard
            id="Wizard"
            color="primary"
            validate
            hideNavigation={props.displayMode === 'create'}
            title="Invoice AI"
            steps={getAdminSteps()}
            subtitle=""
            finishButtonClick={e => {
              if (props.displayMode === 'create') {
                if (e.validations && e.validations.env) onSaveApp(e)
                else {
                  props.setValidationMessage('Please Add Application Name')
                  props.setCreateError(true)
                  setTimeout(() => {
                    props.setCreateError(false)
                  }, 5000)
                }
              } else {
                let validationsForm = props.validationsForm

                if (
                  validationsForm.parameters.destinations?.transform?.value &&
                  !validationsForm.parameters.destinations?.transformValidation?.value
                ) {
                  props.setValidationMessage(
                    'You need to validate the Transform Input before saving'
                  )
                  props.setCreateError(true)
                  setTimeout(() => {
                    props.setCreateError(false)
                  }, 5000)
                  return
                }

                validationsForm.enabled = e.validations.mapping.enabled
                saveToDataBase(validationsForm, () => {}, 'Saved', true, true)
              }
            }}
          />
        </div>
      )
    }

    return (
      <Fade in={true} timeout={250}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12} lg={3} className={classes.padding10}>
            <Card className={classes.appBar}>
              <CardHeader>
                Application List
                <Tooltip title="Create new app">
                  <Button
                    disabled={!props.expirationData.enabled}
                    justIcon
                    round
                    simple
                    color="primary"
                    className={classes.marginRight}
                    onClick={() => {
                      setConfigurationDetails('main')
                      props.onCreateButton()
                      scrolltoTop()
                    }}
                  >
                    <Add className={classes.icons} />
                  </Button>
                </Tooltip>
              </CardHeader>
              <CardBody>
                <List style={{ width: '100%' }}>
                  {props.appList.map((prop, key) => {
                    return (
                      <ListItem
                        className={classes.listItem}
                        button
                        selected={props.selectedAppId === prop.env}
                        key={key}
                      >
                        <Grid container spacing={1}>
                          <Grid item xs={8}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <ListItemAvatar>
                                {props.selectedAppId === prop.env ? (
                                  <Avatar>
                                    <TouchApp />
                                  </Avatar>
                                ) : null}
                              </ListItemAvatar>
                              <ListItemText
                                className={classes.listItemText}
                                primary={prop.appName}
                                secondary={`Type: ${prop.type}`}
                                onClick={() => {
                                  setConfigurationDetails('main')
                                  props.onAppChanged(prop.env)
                                  scrolltoTop()
                                }}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={4}>
                            <ListItemSecondaryAction>
                              {isLoading ? (
                                <div className={classes.circularProgressDefaultApp}>
                                  <CircularProgress />
                                </div>
                              ) : (
                                <FormControlLabel
                                  control={
                                    <Switch
                                      edge="end"
                                      checked={defaultAppUI[prop.env] || false}
                                      onChange={handleChangeSwitch}
                                      name={prop.env}
                                      color="primary"
                                    />
                                  }
                                  label={defaultAppUI[prop.env] ? 'Default' : ''}
                                  labelPlacement="top"
                                  style={{ fontSize: '5px' }}
                                />
                              )}
                            </ListItemSecondaryAction>
                          </Grid>
                        </Grid>
                      </ListItem>
                    )
                  })}
                </List>
              </CardBody>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={12} lg={9} className={classes.padding10}>
            <Breadcrumbs views={breadcrumbViews} />
            <div style={{ position: 'relative' }}>
              {props.formIsLoading ? (
                <Card>
                  <GridContainer justify="center">
                    <div className={classes.circularProgress}>
                      <CircularProgress color="inherit" />
                    </div>
                  </GridContainer>
                </Card>
              ) : props.selectedAppId ||
                props.displayMode === 'edit' ||
                props.displayMode === 'create' ? (
                <Card>
                  <GridContainer spacing={4}>
                    <GridItem xs={12}>
                      <NotificationAlert
                        variant="filled"
                        severity="warning"
                        appInfo={props.expirationData}
                      />
                      {React.cloneElement(returnWizardToDisplay())}
                    </GridItem>
                  </GridContainer>
                </Card>
              ) : (
                <Card>
                  <CardBody>
                    <div className={classes.typo}>
                      <div className={classes.note}>No apps created</div>
                      <h6>Click on the create button to start...</h6>
                    </div>
                  </CardBody>
                </Card>
              )}
              <ExpiredDiv appInfo={props.expirationData} />
            </div>
          </GridItem>
          <Snackbar
            place="br"
            color="success"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.submitSuccess}
            closeNotification={() => props.setSubmitSuccess(false)}
            close
          />
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlert}
            message={props.validationMessage}
            open={props.createError}
            closeNotification={() => props.setCreateError(false)}
            close
          />
          <Snackbar
            place="bl"
            color="danger"
            icon={AddAlert}
            message={
              updatedTenantConfig.isError
                ? updatedTenantConfig.error.response?.data?.message ||
                  'Something went wrong, unabled to save default App'
                : ''
            }
            open={isErrorDefaultApp}
            closeNotification={() => setIsErrorDefaultApp(false)}
            close
          />

          <AlertDialogDefaultApp
            data={isOpenDefaultApp}
            handleClose={handleClose}
            handleOk={handleAcceptModal}
          />
        </GridContainer>
      </Fade>
    )
  }

  if (props.refresh) {
    return <div>{getView()}</div>
  } else return getView()
}
